import "../../CustomStyles/customStyle.css";
import Minagaramlogo from "../../assets/Agarathi_logo.png";
import PlayStorelogo from "../../assets/SharethisApp/PlayStore.svg";
import AppStorelogo from "../../assets/SharethisApp/AppStore.svg";
import STA_MobileScreen from "../../assets/SharethisApp/SharethisApp_MobileScreen.png";
import { Button, Menu, Dropdown, message } from "antd";
import BackgroundImage from "../../assets/background_Image.png";
import {
  STR_Agarathi,
  STR_Tam_agarathi,
  STR_Tam_text1_STA,
  STR_Tam_text2_STA,
  STR_Tam_text4_STA,
  STR_text1_STA,
  STR_text2_STA,
  STR_text3_STA,
  STR_text4_STA,
  STR_Copy_Failure_STA,
  STR_Copy_STA,
  STR_Copy_Success_STA,
  STR_Share,
  STR_Tam_Copy_Failure_STA,
  STR_Tam_Copy_STA,
  STR_Tam_Copy_Success_STA,
  STR_Tam_Share,

} from "../../Config.js/Strings";
import { ShareAltOutlined, LinkOutlined } from "@ant-design/icons";
import { WhatsappShareButton, WhatsappIcon } from "react-share";

import { connect } from "react-redux";

const SharethisApp = ({ isTamil }) => {
  let agarathi_token = localStorage.getItem("agarathi_token");

  const shareUrl = "https://minagaram.com/Min-Landing";

  const handleCopy = async () => {
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(shareUrl);
        message.success(
          isTamil ? STR_Tam_Copy_Success_STA : STR_Copy_Success_STA
        );
      } else {
        // Fallback for non-secure contexts or browsers without clipboard API
        const textArea = document.createElement("textarea");
        textArea.value = shareUrl;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand("copy");
          message.success(
            isTamil ? STR_Tam_Copy_Success_STA : STR_Copy_Success_STA
          );
        } catch (err) {
          message.error(
            isTamil ? STR_Tam_Copy_Failure_STA : STR_Copy_Failure_STA
          );
        } finally {
          document.body.removeChild(textArea);
        }
      }
    } catch (error) {
      message.error(isTamil ? STR_Tam_Copy_Failure_STA : STR_Copy_Failure_STA);
    }
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <WhatsappShareButton url={shareUrl}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </Menu.Item>
      {/* <Menu.Item key="2">
        <FacebookShareButton url={shareUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </Menu.Item>
      <Menu.Item key="3">
        <TwitterShareButton url={shareUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </Menu.Item> */}
      <Menu.Item key="2" onClick={handleCopy}>
        <Button icon={<LinkOutlined />} type="link">
          {isTamil ? STR_Tam_Copy_STA : STR_Copy_STA}
        </Button>
      </Menu.Item>
    </Menu>
  );


  return (
    <div
      className={`${ "pp"}`}
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundSize: "auto",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
      }}
      
    >
      <div className="wholecontainer_STA">
        <div className="container_STA">
          <div className="minagaramlogoContainer_STA">
            <img
              src={Minagaramlogo}
              alt="minagaram logo"
              className="minagaramlogo_STA"
            />
          </div>
          <h1 className="header_STA">
            {isTamil ? STR_Tam_agarathi : STR_Agarathi}
          </h1>
          <p className={`${isTamil ? "text_STA_1_T" : "text_STA_1"}`}>
            {isTamil ? STR_Tam_text1_STA : STR_text1_STA}
          </p>
          <p className={`${isTamil ? "text_STA_T" : "text_STA"}`}>
            {isTamil ? STR_Tam_text2_STA : STR_text2_STA}
          </p>
          {agarathi_token ? (
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            className="shareIcon_STA">
            <Button type="black" icon={<ShareAltOutlined />}>
              {isTamil ? STR_Tam_Share : STR_Share}
            </Button>
          </Dropdown>):
          (
          <p className="text_STA">
            <a
              href="https://minagaram.com"
              target="_blank"
              rel="noopener noreferrer" // for the security feature
            >
              {STR_text3_STA}
            </a>
          </p>
          )}
          <p className={`${isTamil ? "textBold_STA_T" : "textBold_STA"}`}>
            {isTamil ? STR_Tam_text4_STA : STR_text4_STA}
          </p>
          <div className="shareButtonContainer_STA">
            <div className="shareButton_STA">
              <a
                href="https://apps.apple.com/sg/app/min-agaram/id6483002626"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={AppStorelogo}
                  alt="App Store"
                  className="AppStorelogo"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.tamil_agarathi_updated"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={PlayStorelogo}
                  alt="Google Play"
                  className="PlayStorelogo"
                />
              </a>
            </div>
          </div>
        </div>
        <img
          src={STA_MobileScreen}
          alt="MobileScreen"
          className="mobileScreen_STA"
        />
      </div>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state) => ({
  isTamil: state.LanguageReducer.isTamil,
});

export default connect(mapStateToProps)(SharethisApp);
